import React from 'react'

const Navbar = () => {
    return (
        <nav>  
            <div className="nav-title">NEWS AND INFORMATION <span className="orange-text">ai voice interface</span></div> 
        </nav>
            
    )
}

export default Navbar
